// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "y_dz";
export var productDiscoveryAchievementsSection = "y_dM";
export var productDiscoveryBenefitsSection = "y_dy";
export var productDiscoveryCaseSection = "y_dH";
export var productDiscoveryClientSection = "y_dN";
export var productDiscoveryIndustriesSection = "y_dx";
export var productDiscoveryOverviewSection = "y_dF";
export var productDiscoveryProcessSection = "y_dD";
export var productDiscoveryResultsSection = "y_dJ";
export var productDiscoveryRolesSection = "y_dG";
export var productDiscoveryServicesSection = "y_dw";
export var productDiscoveryStagesSection = "y_dB";
export var productDiscoverySuccessStoriesSection = "y_dK";
export var productDiscoveryWhyChooseSection = "y_dL";
export var productDiscroveryImportanceSection = "y_dC";